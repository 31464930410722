html {
  height: 100%;
  background-color: rgb(255, 255, 255);
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: rgb(255, 255, 255);
}

.common-footer-user {
  padding-bottom: 5%;
}