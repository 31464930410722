.waittime_adddelivery_div {
    padding-right: 3.5%;
}

.waittimeunits_adddelivery_div {
    /* padding-left: 10%; */
    height: 100%;
}

.container-deliveryoptionsdisclaimer {
    margin-top: 10%;
    color: rgb(78, 78, 78);
    margin-bottom: -16px;
}

.addDeliveryDomesticDeliveryDataContainer {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 8px;
    border: 2px solid rgb(231, 231, 231);
    margin-top: 4%;
}

.addDeliveryDomesticDeliveryFormContainer {
    margin-left: 5%;
    margin-right: 5%;
    padding: 1%;
    border-radius: 5px;
    margin-top: 4%;
}

.addDeliveryDomesticDeliveryFormControls {
    margin-left: 4%;
}

.enableDomesticDeliveryDisclaimer {
    font-size: 13px;
    color: rgb(107, 107, 107);
}