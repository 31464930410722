.all_product_item_controls {
    margin-top: 2%;
    margin-bottom: 1.5%;
    /* margin-left: 2%; */
}

.all_product_item_Controls_button_n {
    border: none;
    border-radius: 5px;
    background-color: white;
    color: black;
    margin-right: 2.5%;
    padding: 4px 9px 4px 9px;
    color: rgb(250, 103, 103);
    font-size: 15px;
}

.all_product_itemProduct {
    margin: 3%;
    padding: 3%;
    border-radius: 9px;
    border: 2px solid rgb(255, 216, 216);
    background-color: rgb(255, 255, 255);
}