.productmanupulation_container {
    margin-top: 6%;
}

.seller_addProduct_button {
    background-color: rgb(250, 103, 103);
    color: white;
    border: none;
    padding: 1.6% 8% 1.6% 8%;
    border-radius: 5px;
    font-size: 15.5px;
    width: 100%;
}

.addProduct_uploadimagewatermark {
    height: 75px;
    display: block;
}

.addProduct_uploadimagewatermark_text {
    color: gray;
}

.seller_addProduct_button:disabled {
    background-color: rgb(200, 200, 200);
}

.seller_addProduct_button:enabled {
    background-color: rgb(250, 103, 103);
}

.addProduct_uploadedimage {
    height: 225px;
    width: 225px;
    border-radius: 6px;
    padding: 2%;
    border: 2px solid rgb(240, 240, 240);
}