.individualOrderPage_DetailsContainer {}

.cancelOrderIndividualOrderPage {
    margin-top: 3.5%;
}

.cancelOrderIndividualOrderPage button {
    color: rgb(250, 103, 103);
    padding-top: 9px;
    padding-bottom: 9px;
    /* border: none; */
    border: 1px solid rgb(250, 103, 103);
    border-radius: 6px;
    /* background-color: rgb(250, 103, 103); */
    background-color: white;
    width: 100%;
}

.individualOrderPage_DetailsContainer_OrderId {
    font-size: 21px;
    font-weight: 600;
    color: rgb(250, 103, 103);
}

.individualOrderPage_DetailsContainer_Details {
    margin-top: 5%;
}

.individualOrderPage_DetailsContainer_DetailsHeading {
    color: rgb(132, 132, 132);
    font-weight: 300;
    font-size: 15.5px;
}

.individualOrderPage_DetailsContainer_DetailsText {
    color: rgb(56, 56, 56);
    font-weight: 600;
}

.individualOrderPage_DetailsContainer_EstimatedDelivery_InTransit {
    color: green;
}

.individualOrderPage_DetailsContainer_EstimatedDelivery_Delivered {
    font-size: 16px;
    color: black;
}

.individualOrderPage_DetailsContainer_EstimatedDelivery_Canceled {
    color: red;
}

.orderedIndividualProductsContainer_new {
    margin-top: 5%;
    margin-bottom: 5%;
}

.individualOrderPage_DetailsContainer_Divider {
    border-top: 1px solid rgb(156, 156, 156);
}

.individualOrderPage_DetailsContainer_TotalContainer {
    margin-bottom: 4.5%;
    margin-top: 4.5%;
}

.individualOrderPage_DetailsContainer_Total {
    font-size: 19px;
    color: rgb(250, 103, 103);
}

.individualOrderPage_DetailsContainer_CandCContainer {
    margin-bottom: 2%;
}


.individualOrderPage_DetailsContainer_CandC {
    margin-bottom: 1%;
    font-size: 14px;
}

.cancelOrderContainerIndividualOrderPage {
    margin-top: 7%;
}


.acceptButtonIndividualOrderPage {
    width: 100%;
    color: white;
    padding-top: 9px;
    padding-bottom: 9px;
    border: none;
    border-radius: 6px;
    background-color: rgb(250, 103, 103);
    width: 100%;
}

.rejectButtonIndividualOrderPage {
    width: 100%;
    color: rgb(250, 103, 103);
    padding-top: 9px;
    padding-bottom: 9px;
    border: 1px solid rgb(250, 103, 103);
    border-radius: 6px;
    background-color: white;
    width: 100%;
}

.rejectButtonIndividualOrderPage:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}

.acceptButtonIndividualOrderPage:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}