.productAnalysisItem_ProductImg {
    height: 35px;
    width: 35px;
}

.IndividualProductAnalysis_productName {
    padding-left: 4px;
}

.ProductAnalyticsItem_container {
    border: 2px solid rgb(255, 237, 237);
    border-radius: 5px;
    padding: 2.2%;
    margin-bottom: 4%;
}

.ProductAnalyticsItem_details {
    padding-bottom: 2.1%;
    border-bottom: 2.5px solid rgb(255, 237, 237);
}

.ProductAnalyticsItem_analytics {
    padding-top: 2%;
}

.ProductAnalyticsItem_analytics_img {
    height: 24px;
    /* margin-bottom: 4%; */
}

.ProductAnalyticsItem_analytics_individualcont {
    padding-right: 5%;
    padding-left: 5%;
}

.ProductAnalyticsItem_analytics_text {
    padding-left: 5px;
}

.ProductAnalyticsItem_analytics_img_view {
    height: 24px;
    margin-bottom: 4%;
}