.mainChangeShopDP_container {
    margin-top: 7.5%;
}


.changeCropper_ChangeShopDP {
    margin-top: 10%;
    margin-bottom: 10%;
}

.sellerUploadDPDrawer {
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 200;
    transition: transform 0.3s ease;
    transform: translateY(300%);
    height: 70%;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.sellerUploadDPDrawer.open {
    transform: translateY(0%);
}

.innerdrawerUploadImage {}