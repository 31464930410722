.merchantSideDrawer {
    overflow-y: auto;
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    left: 0;
    top: 0;
    width: 73%;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease;

}

.merchantSideDrawer::-webkit-scrollbar {
    width: 0px;

}

.merchantSideDrawer.open {
    transform: translateX(0%);
}

.headerSideDrawerSeller {
    margin-top: 5%;
    margin-left: 6%;
    margin-right: 6%;
}

.headerSideDrawerSeller_username_color {
    color: rgb(250, 103, 103);
}

.sellerIconSideDrawer {
    margin-top: 1.5px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.greaterThanSideDrawerIcon {
    height: 18px;
}