.merchantNavBar {
    z-index: 1;
    height: 3.8rem;
    width: 100%;
}

.merchantBars {
    height: 25px;
    /* margin-bottom: 2.2px; */
}

.merchantNavOrders_img {
    /* margin-top: 4px; */
    height: 29px;
    /* margin-bottom: 3px; */
}

.merchantDrawerToggleButton {
    padding: 0px;
    margin-top: 2.5px;
    border: none;
    background-color: rgb(255, 255, 255);
}

.merchantNavOrders {
    /* margin-right: 12px; */
    border: none;
    background-color: rgb(255, 255, 255);
    /* margin-left: 8px; */
}

.merchantNavSignIn {
    font-size: 1.1rem;
    color: black;
    text-decoration: none;
    /* margin-left: 4px; */
    /* margin-left: 16px; */
    border: none;
    background-color: rgb(255, 255, 255);
    /* padding-top: 3px; */
    color: rgb(250, 103, 103);
}

.merchantNavSignIn:hover {
    color: rgb(250, 103, 103);
}

.merchantNavBarLogo img {
    height: 35px;
    /* margin-left: 18.5%; */
}

.merchantNavAccount img {
    height: 28.5px;
    width: 28.5px;
    border-radius: 50%;
}

.merchantNavAccount {
    /* padding-left: 0px;
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 1px; */
    /* margin-right: 16px; */
}

.pending-orders-pill-bg {
    background-color: rgb(250, 103, 103)
}