.checkavailability_sellercracc {
    margin-top: 1.3%;
    padding-right: 1.2%;
    color: rgb(250, 103, 103);
    /* margin-bottom: 0.4%; */
}

.nextButtonCustom:disabled {
    color: rgb(46, 46, 46);
    background-color: rgba(250, 103, 103, 0.606);
}

.nextButtonCustom:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}

.input-group-text-custom {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(250, 103, 103);
    text-align: center;
    white-space: nowrap;
    border: 1px solid #ced4da;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}


.class_BigHeadingAll {
    color: rgb(66, 66, 66);
    font-size: 65px;
}