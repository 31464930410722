.sellerSmallNavBar_text {
    margin-left: 16px;
    color: rgb(50, 50, 50);
    font-size: 16.5px;
}

.sellerSmallNavBar_logo {
    margin-right: 14px;
}

.sellerSmallNavBar_container {
    z-index: 10;
    padding-left: 5%;
    padding-top: 4%;
    padding-bottom: 3.6%;
    background-color: rgb(255, 255, 255);
}

.sellerLogoNavBar_container {
    z-index: 10;
    padding-top: 6.5%;
    padding-bottom: 3.6%;
    background-color: rgb(255, 255, 255);
}