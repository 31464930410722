.dashboard_icon_items_root {
    height: 33px;
    width: 33px;
}

.containerDashboardItem {
    height: 90px;
    width: 110px;
    margin: 5px;
    border-radius: 8px;
    border: 2px solid rgb(255, 237, 237);
}

.containerDashboardItem:first-child {
    margin-left: 0px;
}

.containerDashboardItem:last-child {
    margin-right: 0px;
}

.dashboardSellerRoot {}

.CategoryDashboardText {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 6px;
    margin-top: 10px;
    padding-left: 1px;
    padding-bottom: 2%;
    border-bottom: 2px solid rgb(255, 208, 208);
}

.dashbaord_navbar {
    font-weight: 500;
    color: rgb(52, 52, 52);
    background-color: rgb(255, 242, 188);
    padding-top: 4%;
    padding-bottom: 3.9%;
}

.dashboardPendingOrders {
    margin-top: 2%;
    padding: 4%;
    text-align: center;
    border-radius: 8px;
    border: 2px solid rgb(255, 237, 237);
}

.dashboardPendingOrders_container {
    margin-top: 5%;
    margin-bottom: 6%;
}