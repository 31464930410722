.noProductsInShop_myrootshop {
    padding-top: 22%;
    padding-bottom: 22%;
}

.noBSProductsInShop_myrootshop {
    padding-top: 22%;
    padding-bottom: 22%;
}

.edit-root_edit_sellerbaner {
    margin-top: 1.2%;
    padding-right: 2.8%;
    color: rgb(250, 103, 103);
}

.selected_MENU {

    border: 1px solid rgb(230, 230, 230);
}