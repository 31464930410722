#QRDownloadContainer {
    background-color: rgb(250, 103, 103);
    width: 100%;
    /* padding: 42px 0px 0px 0px; */
    border-top: 0.5px solid rgb(250, 103, 103);
    border-left: 0.5px solid rgb(250, 103, 103);
    border-right: 0.5px solid rgb(250, 103, 103);
}

.QRDownloadContainer_logo {
    height: 55px;
}

.QRDownloadContainer_logo_container {
    /* margin-top: 80px; */
    padding-top: 30px;
}


.QRDownloadContainer_QR {
    margin-top: 20px;
}

.QRDownloadContainer_TextDescription {}

.QRDownloadContainer_Slogan {
    margin-top: 15px;
    color: rgb(250, 103, 103);
    font-size: 33px;
}

.QRDownloadContainer_ShopName {
    font-size: 25px;
    color: rgb(255, 255, 255);
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 10px;
    border-bottom: 2px solid rgb(255, 255, 255);
}

.QRDownloadContainer_DownloadButtonContainer {
    margin-bottom: 12%;
}

.QRDownloadContainer_CredentialsPrinted {
    font-size: 15.5px;
    background-color: rgb(250, 103, 103);
    margin: 20px 0% 0px 0%;
    padding-top: 8px;
    padding-bottom: 10px;
}

.QRDownloadContainer_CredentialsPrinted span {
    margin: 0% 1.5% 0% 1.5%;
}


.downloadButtonCustom {
    font-size: 18px;
    background-color: white;
    border: 1px solid rgb(250, 103, 103);
    color: rgb(250, 103, 103);
    border-radius: 5px;
    width: 100%;
    padding: 3%;
}

.downloadButtonCustom:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}

.QRDownloadContainer_logoSeller {
    height: 40px;
}

.WPQR_tagline_GreatChoice {
    margin-top: 3.5%;
    font-size: 37px;
    color: rgb(250, 103, 103);
}

.WPQR_tagline_GreatChoiceText {
    margin-top: -5px;
    font-size: 23px;
    color: rgb(0, 0, 0);
}

.WPQR_tagline_GreatChoiceText_1 {
    margin-top: -5px;
    font-size: 22px;
    color: rgb(0, 0, 0);
}

.download_qr_imageIcon {
    padding-top: 1%;
}

.download_qr_imageIcon img {
    height: 37px;
    /* margin-right: 3px; */
}

.whatNextDownloadQR_textContainer {
    margin-top: 5%;
    padding: 2%;
}

.whatNextDownloadQR_textContainer_individual {
    margin-top: 4%;
}

.whatNextDownloadQR_textContainer_individual:first-child {
    margin-top: 0%;
}

.whatNextDownloadQR_textContainer_bold {
    color: rgb(250, 103, 103);
    display: block;
}

.whatNextDownloadQR_textContainer_text {}

.whatNextDownloadQR_easy {
    margin-top: 1%;
}

.whatNextDownloadQR_ButtonContainer {
    margin-top: 6%;
}

.innercontainer_qr_white {
    border-radius: 10px;
    background-color: white;
    margin: 50px 28px 50px 28px;
}

.QRDownloadContainer_logoSeller_container {
    background-color: rgb(255, 239, 239);
    margin-top: 25px;
    padding: 11px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}