.ExistingCouponsItem {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 8px;
    border: 2px solid rgb(255, 230, 230);
    margin-top: 4%;
}

.delete_coupon_seller {
    color: rgb(250, 103, 103);
}