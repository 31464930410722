p {
  color: rgb(19, 19, 19);
}

.oopsApp {
  padding-left: 6%;
  padding-right: 5%;
}

.oopsApp_1 {
  margin-top: 2%;
}

.passHideUnhideButton {
  background-color: rgb(255, 255, 255);
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0.375rem;
}

.passHideUnhideButton img {
  height: 22px;
}

.showPasswordOrNotCheckBoxContainer {
  margin-top: 4%;
  padding-left: 1%;
}

.useSmallScreen_BeautifulContainer {
  padding: 4% 5% 4.2% 5%;
  background-color: rgb(250, 103, 103);
}

.banner_two_Swoopkart {
  padding-top: 3.1%;
  padding-bottom: 3.1%;
}

.qrCodeSeller {
  min-height: 220px;
  min-width: 220px;
  max-height: 220px;
  max-width: 210px;
  /* border: 1px solid rgb(255, 177, 177); */
  /* text-align: center; */
}

.bgoflocationroot {
  background-color: rgb(38, 251, 255);
  /* background-color: rgba(250, 103, 103, 0.956); */
}


.formErrorSeller {
  background-color: rgba(255, 0, 0, 0.070);
  padding: 3%;
  text-align: center;
  border-radius: 6px;
  border: 1px solid rgb(255, 0, 0);
  font-size: 15px;
  font-weight: 500;
  color: rgb(255, 0, 0);
}

.formSuccessSeller {
  background-color: rgb(212, 237, 218);
  padding: 3%;
  text-align: center;
  border-radius: 6px;
  border: 1px solid rgb(81, 113, 89);
  font-size: 15px;
  color: rgb(50, 78, 57);
}

.formGeneralSeller {
  background-color: rgb(255, 255, 255);
  font-weight: 500;
  padding: 3%;
  text-align: center;
  border-radius: 6px;
  /* border: 2px solid rgb(234, 234, 234); */
  border: 2px solid rgb(255, 222, 222);
  font-size: 15px;
  color: rgb(34, 34, 34);
}


.smallButtonControlBorder {
  border: 2px solid rgb(255, 229, 229);
}