.innerFooter {
    padding: 20px 0px 20px 0px;
    margin: 0px 5px 0px 5px;
}

.linksClass {
    text-decoration: none;
    display: block;
}

.socialMedia a {
    padding: 4px;
    margin-left: 18px;
    margin: 0px;
}

/* .logos {
    height: 20%;
} */

.logos img {
    height: 25px;
}

#logo {
    padding-left: 2%;
}

.logos {
    margin-top: 5%;
}

.copyrights p {
    text-align: center;
    margin-top: 6%;
    color: white;
}