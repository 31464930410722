.globe-Image-region-container {
    margin-top: 6%;
}

.container-regiondisclaimer {
    margin-top: 5%;
    color: rgb(78, 78, 78);
    margin-bottom: -16px;
}

.sellerSellingRegion_globalDeliveryContainer {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 5px;
    border: 2px solid rgb(255, 227, 227);
    margin-top: 4%;
}

.sellerSellingRegion_globalDelivery_Label {
    padding-left: 1%;
}


.newDisabledFormControl:disabled {
    background-color: white;
}

.fontSizeForToggle {
    font-size: 18px;
}