#floatingInput_shop_edit_name:disabled {
    background-color: lemonchiffon;
}

#floatingInput_shop_edit_name:enabled {
    background-color: white;
}

#floatingInput_shop_phone_edit:disabled {
    background-color: lemonchiffon;
}

#floatingInput_shop_phone_edit:enabled {
    background-color: white;
}

#floatingInput_shop_email_edit:disabled {
    background-color: lemonchiffon;
}

#floatingInput_shop_email_edit:enabled {
    background-color: white;
}

#floatingInput_shop_category_edit:disabled {
    background-color: lemonchiffon;
}

#floatingInput_shop_category_edit:enabled {
    background-color: white;
}

#floating_Textarea_description_edit:disabled {
    background-color: lemonchiffon;
}

#floating_Textarea_description_edit:enabled {
    background-color: white;
}

#floating_Textarea_address_edit:disabled {
    background-color: lemonchiffon;
}

#floating_Textarea_address_edit:enabled {
    background-color: white;
}

#floatingInput_shop_edit_usn:disabled {
    background-color: lemonchiffon;
}

#floatingInput_shop_edit_usn:enabled {
    background-color: white;
}

.seller_banner_edit {
    width: 100%;
}