.smallNavBar_text {
    padding-top: 0.6%;
    margin-left: 16px;
    color: rgb(50, 50, 50);
    font-size: 16.5px;
}

.smallNavBar_logo {
    margin-right: 14px;
}

.smallNavBar_container {
    z-index: 10;
    padding-left: 5%;
    padding-top: 4%;
    padding-bottom: 3.6%;
    background-color: rgb(255, 255, 255);
}


.sideDrawer {
    overflow-y: auto;
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    left: 0;
    top: 0;
    width: 73%;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease;

}

.sideDrawer.open {
    transform: translateX(0%);
}

.accountHolderIconSideDrawer {
    margin-top: 1.5px;
    height: 35px;
}

.headerSideDrawer {
    margin-top: 5%;
    margin-left: 6%;
    margin-right: 6%;
}

.headerSideDrawer h5 {
    padding-top: 3%;
}

.linkSideDrawer {
    /* color: #FA6767; */
    color: black;
    padding: 4% 3% 4% 3%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    /* border: 1px solid rgb(255, 237, 237); */
    text-decoration: none;
    font-size: 15.2px;
    font-weight: 600;
}

.categorySideDrawer {
    padding-left: 4.6%;
    /* border-top: 1px solid rgb(163, 163, 163); */
    padding-top: 3%;
    margin-top: 5%;
    padding-bottom: 3%;
}

.sidebarLinkActive {
    color: rgb(54, 54, 54);
    background-color: rgb(255, 237, 237);
}

.sidebarLinkNotActive {
    color: rgb(54, 54, 54);
    background-color: rgb(248, 249, 250);
}

.linkSideDrawer span {}

.categorySideDrawer span {
    color: rgb(250, 103, 103);
}

.categoryContainerSideDrawer {
    margin-top: 4%;
    border-top: 3px solid rgb(255, 233, 233);
}

.sidedrawer_footer {
    /* margin-top: 11%;
    margin-bottom: 6%; */
}

.sidedrawer_footer_text {
    color: grey;
}

.headerSideDrawer_username_color {
    color: rgb(250, 103, 103);
}

.accountHolderIconSideDrawer {
    border-radius: 50%;
}

.navBar {
    height: 3.8rem;
    width: 100%;
    z-index: 1;
}

.bars {
    height: 25px;
    /* margin-bottom: 2.2px; */
}

.cart {
    margin-top: 4px;
    height: 24px;
    margin-bottom: 2.2px;
}

.drawerToggleButton {
    margin-top: 1px;
    padding: 0px;
    border: none;
    background-color: rgb(255, 255, 255);
}

.navCart {
    /* margin-right: 12px; */
    border: none;
    background-color: rgb(255, 255, 255);
    /* margin-left: 8px; */
}

.navSignIn {
    font-size: 1.1rem;
    color: black;
    text-decoration: none;
    /* margin-left: 33px; */
    border: none;
    background-color: rgb(255, 255, 255);
    /* padding: 3px 6px 4px 6px; */
    color: rgb(250, 103, 103);
}

.navBarLogo img {
    /* margin-left: 15%; */
}

.navAccount img {
    height: 30.5px;
    border-radius: 50%;
}

.navAccount {
    /* margin-left: 22px; */
    /* border-radius: 50%; */
    margin-right: 10px;
}

.cart-pill-bg {
    background-color: rgb(250, 103, 103)
}

.class-0padding {
    padding: 0px;
    /* margin-right: -15px; */
}

.border-black {
    border-bottom: 1.1px solid rgb(208, 208, 208);
}



.headerSideDrawer_notlogged {
    margin-top: 3%;
    margin-left: 6%;
    margin-right: 6%;
}

.innerFooter {
    padding: 25px 0px 20px 0px;
    margin: 0px 5px 0px 5px;
}

.outerFooter {
    background-color: rgb(28, 29, 31);
}

.linksClass {
    text-decoration: none;
    display: block;
    color: rgb(255, 255, 255);
    font-weight: 500;
    margin-bottom: 1.5px;
}

.linksClass:hover {
    color: rgb(255, 184, 184);
}

.linksFooter {
    /* color: black; */
}

.socialMedia a {
    padding: 4px;
    margin-left: 18px;
    margin: 0px;
}

/* .logos {
    height: 20%;
} */

.logosFooter img {
    height: 33px;
}

#logo {
    padding-left: 2%;
}

.logosFooter {
    margin-top: 2%;
    margin-bottom: 5%;
}

.logosFooter_one img {
    height: 33px;
}

.logosFooter_one {
    margin-top: 2%;
    margin-bottom: 33px;
}

.copyrightsFooter p {
    text-align: center;
    margin-top: 6%;
    color: rgb(255, 255, 255);
}

.color_footer_category {
    color: rgb(250, 103, 103);
}

.footer_terms_conditions_text {
    color: rgb(255, 255, 255);
}

.footer_SocialHandle img {
    height: 24px;
}

.footer_SocialHandle {
    margin-right: 8px;
    display: inline;
}

.footer_SocialHandle_one img {
    height: 22px;
}

.footer_SocialHandle_one {
    margin-right: 2px;
    display: inline;
}

.loadingBackdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    z-index: 1000;
    top: 0;
    left: 0;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.794);
    z-index: 100;
    top: 0;
    left: 0;
}

.spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateY(-50%);
    transform: translateX(-50%);
    text-align: center;
    margin: 0;
    z-index: 10000;
}

.spinner:after {
    content: ' ';
    display: block;
    width: 50px;
    height: 50px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid rgb(0, 0, 0);
    border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(250, 103, 103) rgb(250, 103, 103);
    animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.user_dp {
    height: 80px;
}

.user_container {
    background-color: rgb(255, 255, 255);
}

.userNav_Container {
    background-color: rgb(255, 255, 255);
    padding-left: 5%;
    /* margin-top: 4%; */
    padding-bottom: 3.6%;
    padding-top: 4%;
}

.user_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 49%;
}

.userNav_Text {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.user_dp {
    border-radius: 50%;
}

.user_ControlsAndNavigation_box {
    margin-top: 5%;
}

.user_control_container_box {
    color: black;
    padding: 3% 2% 3% 0%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border: 2px solid rgb(255, 237, 237);
    text-decoration: none;
}



.user_control_container_box_text {
    padding-left: 5px;
    color: #000000;
    font-weight: 600;
    font-size: 15px;
}

.user_control_container_box_img {
    padding-bottom: 1%;
    height: 21px;
}

.user_MoreControls {
    margin-top: 6%;
    border-top: 4px solid rgb(214, 214, 214);
}

.user_MoreControls_box {
    color: black;
    padding: 3% 2% 3% 2%;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    /* border: 1px solid rgb(255, 237, 237); */
    text-decoration: none;
}

.user_MoreControls_box_category {

    padding-left: 4.6%;
    color: rgb(250, 103, 103);
    /* border-top: 1px solid rgb(163, 163, 163); */
    padding-top: 3%;
    margin-top: 5%;
    padding-bottom: 3%;
}

.user_MoreControls_box_text {
    padding-left: 13px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 15px;
}

.user_MoreControls_box_img {
    padding-bottom: 1%;
    height: 22px;
}

.user_dp_and_name {
    margin-bottom: 5%;
}

.nameFields label {
    display: block;
    /* width: 95%; */
}

.nameFields {
    margin-top: 5%;
}

.nameFields input {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgb(0, 0, 0);
    border-radius: 5px;
    height: 48px;
    padding: 4%;
}


.nameFields input:focus {
    border: 1.5px solid rgb(0, 0, 0);
    background-color: rgba(250, 103, 103, 0.063);
}

.phoneNumberField {
    margin-top: 3.6%;
}

.phoneNumberField input {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgb(0, 0, 0);
    border-radius: 5px;
    height: 48px;
    padding: 3%;
}

.phoneNumberField input:focus {
    border: 1.5px solid rgb(0, 0, 0);
    background-color: rgba(250, 103, 103, 0.063);
}

.emailField {
    margin-top: 3.6%;
}


.emailField input {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgb(0, 0, 0);
    border-radius: 5px;
    height: 48px;
    padding: 3%;
}

.emailField input:focus {
    border: 1.5px solid rgb(0, 0, 0);
    background-color: rgba(250, 103, 103, 0.063);
}

.fnd {
    margin-right: 2%;
}

.lnd {
    margin-left: 2%;
}

.nextButton {
    margin-top: 12%;
}

.headingSignUp {
    margin-top: 6%;
}

.verificationText {
    margin-top: 2%;
}

.nextButton button {
    font-weight: 450;
    width: 100%;
    height: 40px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    padding: 3px 30px;
    color: rgb(255, 255, 255);
    background-color: rgb(250, 103, 103);
}

.otpBox {
    margin-top: 7%;
}

.otpFields {
    display: inline-block;
    height: 2.6rem;
    width: 15%;
    border: none;
    border-bottom: 2px solid black;
    text-align: center;
    background-color: rgb(255, 255, 255);
}

/* .otpFields:hover {
    border-bottom: 2px solid rgb(250, 103, 103);
} */

.otpSpacer {
    margin-left: 4%;
    margin-right: 4%;
    font-size: x-large;
}

.createPass {
    margin-top: 4%;
}

.createPass label {
    color: rgb(0, 0, 0);
    display: block;
}

.createPass input {
    font-size: 1.3rem;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgb(0, 0, 0);
    border-radius: 5px;
    width: 100%;
    height: 48px;
    padding: 3%;
}


.createPass input:focus {
    border: 1.5px solid rgb(0, 0, 0);
    background-color: rgba(250, 103, 103, 0.063);
}

.createPassButton {
    margin-top: 3%;
}

.createPassButton button {
    width: 100%;
    height: 42px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    padding: 3px 30px;
    color: rgb(255, 255, 255);
    background-color: rgb(250, 103, 103);
}

.disclaimerCreateAccount {
    margin-left: 1%;
    margin-top: 8%;
}

.disclaimerCreateAccount span {
    color: rgb(103, 103, 103);
}

.shop_page_container {
    /* margin-top: 5%; */
}

.shop_page_sellerbanner img {
    width: 100%;
}

.shop_page_name {
    color: rgb(250, 103, 103);
}

.shop_page_heading {
    color: black;
}

.sellerBestSellingContainer {
    max-height: 275px;
    border-top: 2px solid rgb(255, 212, 212);
    border-bottom: 2px solid rgb(255, 212, 212);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.sellerBestSellingContainer::-webkit-scrollbar {
    display: none;
}

.shop_page_sellerbanner_logo img {
    margin-left: 10%;
    border-radius: 50%;
    height: 70px;
}

.search {
    background-color: rgb(255, 255, 255);
}

.container-searchResultProducts {
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    background-color: rgb(255, 255, 255);
    margin-bottom: 10%;
}

.savedForLaterContainer {
    background-color: rgb(255, 255, 255);
    padding-left: 5%;
    /* margin-top: 4%; */
    padding-bottom: 3.6%;
    padding-top: 4%;
}

.savedForLater_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 42%;
}

.mySavedForLater {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.SavedForLaterItemsContainer {
    padding-bottom: 0%;
}

.SavedForLaterEmpty {
    margin: 5% 10% 5% 10%;
    padding: 5% 10% 5% 10%;
    text-align: center;
}

.savedForLaterItem {
    border-bottom: 1px solid rgb(250, 103, 103);
    /* border-bottom: 1px solid rgb(57, 57, 57); */
    padding-bottom: 1%;
}


.savedForLaterProduct {
    padding: 3% 0% 3% 0%;
    background-color: rgb(255, 255, 255);
}

.savedForLaterItemImgAndInfoContainer {
    /* margin-left: 2%; */
    /* padding: 3px 5px 3px 7px; */
}

.savedForLaterItemImage img {
    border-radius: 5px;
    height: 80px;
}

.savedForLaterItemInfo {
    /* margin: 4%; */
}

.savedForLaterItemInfo_ProductTitle {
    padding-top: 0.5%;
    display: block;
}

.savedForLaterItemInfo_ProductPrice {
    /* padding-bottom: 4%; */
    font-size: 18px;
    display: block;
}

.savedForLaterItemControls_DecreaseQuantity {
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    border-left: 1px solid rgb(250, 103, 103);
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}


.savedForLaterItemControls_IncreaseQuantity {
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    border-right: 1px solid rgb(250, 103, 103);
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.savedForLaterItemControls_Quantity {
    /* background-color: rgb(255, 231, 231); */
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    padding: 5px 12px 5px 12px;
}

.savedForLaterItemControls_button {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: rgb(250, 103, 103);
    margin-left: 5px;
    margin-right: 5px;
    font-size: 13px;
}

.savedForLaterItemControls {
    margin-top: 4%;
    margin-left: 2%;
}

.innerSavedForLaterIsEmpty {}

.results_text_search {
    /* padding-left: 2%; */
    padding-bottom: 2%;
}

.shop_page_sellerbanner_logo {
    padding-top: 2%;
    padding-left: 5%;
}

.root {
    margin-top: 5%;
}

.adCarousel {
    max-height: 260px;
    display: flex;
    overflow-x: auto;
}

.bg-swoopkart {
    background-color: rgb(255, 255, 255);
}

.recomendationsRoot {
    /* background-color: rgb(254, 245, 221); */
    /* background-color: rgb(255, 247, 247); */
    max-height: 275px;
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.popularRoot {
    /* background-color: rgb(254, 245, 221); */
    /* background-color: rgb(255, 247, 247); */
    max-height: 275px;
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.bestSellerRoot {
    /* background-color: rgb(254, 245, 221); */
    /* background-color: rgb(255, 247, 247); */
    max-height: 275px;
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.trendingRoot {
    /* background-color: rgb(254, 245, 221); */
    /* background-color: rgb(255, 247, 247); */
    max-height: 275px;
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.productRoot {
    min-width: 42%;
    max-height: 255px;
    text-align: start;
    border-left: 1px solid rgb(255, 212, 212);
    border-right: 1px solid rgb(255, 212, 212);
    background-color: rgb(255, 255, 255);
    margin: 0% 0% 0% 0%;
}

.rootWholeContainer {
    padding-top: 9%;
}

.rootProductImage {
    /* display: block; */
    margin-top: 1%;
    margin-bottom: 7px;
    height: 110px;
    width: 110px;
    border-radius: 7px;
    border: 2px solid rgb(240, 240, 240);
    padding: 2%;
}

.rootProductImage_cont {
    /* display: block; */
}

.productRoot:first-child {
    border-left: 1px solid rgb(255, 212, 212);
}

.productRoot:last-child {
    border-right: 1px solid rgb(255, 212, 212);
}

.rootProductTitle {
    margin-right: 5%;
    margin-top: 3.5%;
    margin-left: 3%;
    display: block;
    font-size: 15px;
}

.rootProductPrice {
    margin-left: 3%;
}

.rootProductPrice span {
    margin-bottom: 2%;
    display: block;
}

.productRootInner {
    padding: 8% 3% 8% 3%;

}

.rootMerchantBannerImg {
    height: 90px;
}

.rootMerchantBannerText {
    height: 90px;
    background-color: rgb(109, 244, 247);
}

.rootMerchantBannerText span {
    padding-left: 2%;
    display: block;
}

.rootUserBannerText {
    height: 90px;
    background-color: rgb(1, 194, 255);
}

.rootUserBannerText span {
    padding-left: 4%;
    display: block;
}

.rootUserBannerImg {
    height: 90px;
}

.margin-bottom-4p {
    margin-bottom: 0%;
}

.categoriesRoot_category {
    min-width: 22%;
    max-height: 185px;
    text-align: start;
    background-color: rgb(255, 255, 255);
    margin: 0% 0% 0% 0%;
}

.categoriesRoot_category img {
    display: block;
    padding: 3%;
    height: 50px;
}

.___text strong {
    font-size: 13px;
}

.pageNotFoundContainer {
    margin-top: 10%;
}

.shopNotFoundContainer {
    margin-top: 5%;
    margin-bottom: 5%;
}

.pageNotFoundContainer_text {
    margin-top: 10px;
    text-align: center;
    color: dimgray;
}

.pageNotFoundContainer_error {
    color: dimgray;
}

.class_404 {
    color: rgb(66, 66, 66);
    margin-top: -5px;
    font-size: 65px;
    margin-bottom: -16px;
}

.categoriesRoot_container {
    max-height: 205px;
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 5% 0%;
    padding-top: 3.5%;
}

.orderHistoryContainer {
    background-color: rgb(255, 255, 255);
    padding-left: 5%;
    /* margin-top: 4%; */
    padding-bottom: 3.6%;
    padding-top: 4%;
}

.orderHistory_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 45%;
}

.orderHistoryText {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.orderHistoryItemsContainer {
    /* border-top: 1px solid rgb(250, 103, 103); */

}

.orderHistoryEmpty {
    margin: 5% 10% 5% 10%;
    padding: 5% 10% 5% 10%;
    text-align: center;
}

.MyAccount_addNewAddresses {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 8px;
    border: 2px solid rgb(231, 231, 231);
}

.MyAddresses_container {
    margin-top: 8%;
}

.MyAccount_addNewAddresses_img img {
    height: 28px;
}

.MyAccount_addNewAddresses_text {
    /* padding-left: 2%; */
    font-size: 17px;
    color: rgb(0, 0, 0);
}

.MyAccount_addNewAddresses_text_exp {
    font-size: 13px;
    font-weight: 600;
}

.addNewAddresses_form {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5% 5% 6.5% 5%;
    border-radius: 5px;
    border: 2px solid rgb(231, 231, 231);
}

.addNewAddresses_form_controls {
    color: rgb(250, 103, 103);
}

.addNewAddresses_form_controls_cancel {
    margin-right: 2%;
}

.addNewAddresses_form_controls_add {
    margin-left: 2%;
}

.AllExistingAddressesContainer {
    padding-bottom: 6%;
}

.addNewAddresses_form_heading {
    text-align: center;
    margin-bottom: 5%;
}

.ExistingAddressItem {
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    border-radius: 5px;
    border: 1px solid rgb(255, 227, 227);
    margin-top: 4%;
}

.add_address_seller {
    color: rgb(250, 103, 103);
}


.orderHistoryItem {
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 216, 216);
    padding: 3%;
    margin: 3%;
    border-radius: 9px;
}

.orderHistoryItemImgAndInfoContainer {
    margin-left: 2%;
    padding: 0px 0px 0px 8px;
}

.orderHistoryItemInfo {
    margin: 4% 4% 1% 4%;
}

.orderHistoryItemInfo_ProductTitle {
    padding-top: 0.5%;
    display: block;
}

.orderHistoryItemInfo_ProductPrice {
    padding-bottom: 3%;
    display: block;
}

.orderHistoryItemControls_DecreaseQuantity {
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    border-left: 1px solid rgb(250, 103, 103);
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}


.orderHistoryItemControls_IncreaseQuantity {
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    border-right: 1px solid rgb(250, 103, 103);
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.orderHistoryItemControls_Quantity {
    background-color: rgb(255, 231, 231);
    border-top: 1px solid rgb(250, 103, 103);
    border-bottom: 1px solid rgb(250, 103, 103);
    padding: 5px 12px 5px 12px;
}

.soldby_orderHistoryItemInfo_ProductTitle {
    color: rgb(250, 103, 103);
}

.orderHistoryItemInfo_status_InTransit {
    color: green;
}

.orderHistoryItemInfo_status_Delivered {
    color: rgb(250, 103, 103);
}

.orderHistoryItemInfo_status_Canceled {
    color: red;
}

.orderHistoryItemInfo_SoldBy {
    font-size: 15.5px;
    color: rgb(29, 29, 69);
}

.orderHistoryItemInfo_OrderedOn {
    font-size: 13.5px;
    font-weight: 600;
    color: gray;
}

.orderHistoryItemInfo_ImagesOfProducts {
    margin-top: 3%;
    margin-bottom: 3.2%;
}

.orderHistoryItemInfo_ImagesOfProducts img {
    height: 33px;
    width: 33px;
    margin-right: 2%;
    border-radius: 3px;
    border: 1px solid rgb(255, 216, 216);
}

.orderHistoryItemInfo_ImagesOfProductsEnd {
    display: inline-block;
    height: 33px;
    width: 33px;
    background-color: rgb(240, 239, 242);
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
}

.orderHistoryItemInfo_PandOpHeading {
    font-size: 13.5px;
    color: gray;
}

.orderHistoryItemInfo_PandOp {
    font-size: 15px;
    font-weight: 600;
}

.orderHistoryItemControls_button1 {
    /* background-color: rgba(255, 0, 0, 0.409); */
    background-color: rgb(255, 255, 255);
    /* border: none; */
    /* border: 1px solid rgb(43, 43, 43); */
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: rgb(0, 0, 0);
    margin-left: 3%;
}

.orderHistoryItemControls_button2 {
    /* background-color: rgba(255, 239, 147, 0.566); */
    background-color: rgb(255, 255, 255);
    /* border: none; */
    /* border: 1px solid rgb(43, 43, 43); */
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: black;
    margin-left: 3%;
}

.orderHistoryItemControls {
    margin-top: 4%;
}

.orderHistoryItem_pb {
    padding-top: 0.3%;
    padding-bottom: 1%;
    /* color: rgb(250, 103, 103); */
}

.ProductPage {
    /* min-height: 100%; */
    /* overflow-y: auto; */
    /* background-color: rgb(255, 255, 255); */
    /* margin-bottom: 4%; */
}

.ProductPage_NavBar {
    border-bottom: 1px solid rgb(90, 90, 90);
    /* background-color: rgb(255, 255, 255); */
    width: 100%;
    position: sticky;
    left: 0;
    top: 0;
    padding-top: 3.5%;
    padding-bottom: 3.5%;


}

.ProductPage_NavBar_img {
    margin-left: 5%;
    margin-bottom: 0.5%;
}

.ProductPage_NavBar_img_logo {
    margin-top: 0.1%;
    margin-left: 75%;
    margin-bottom: 0.4%;
}

.ProductPage_ImageContainer {
    margin-top: 4%;
}

.ProductPage_ImageContainer img {
    border-radius: 6px;
    height: 320px;
    width: 320px;
}

.ProductPage_Rating {
    margin-top: 4.5%;
}

.ProductPage_TitleAndBrand {
    padding-top: 2.5%;
}

.ProductPage_Pricing {
    padding-top: 4%;
}

.ProductPage_Pricing_MRP {
    text-decoration: line-through;
    color: rgb(73, 73, 73);
}

.ProductPage_Pricing_Discount {
    color: red;
    padding-right: 1%;
}

.ProductPage_Delivery {
    margin-top: 4.5%;
    border-top: 3px solid rgb(176, 176, 176);
}

.ProductPage_Delivery_Container {
    margin-top: 4%;
}

.ProductPage_Controls_AddToCart button {
    /* border: 1px solid rgb(250, 103, 103); */
    margin-top: 4.1%;
    margin-bottom: 3%;
    border: none;
    background-color: rgb(250, 103, 103);
    color: white;
    border-radius: 5px;
    width: 100%;
    height: 45px;
}

.ProductPage_Controls_Stock {
    margin-top: 5%;
    color: green;
}

.IPItemControls_button {
    margin-left: 3.2%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
}

.IPItemControls_button:hover,
.IPItemControls_button:focus {
    background-color: rgba(250, 103, 103, 0.262);
}

.ProductPage_VendorDeltils {
    text-decoration: none;
    color: black;
    margin-top: 0.5%;
    font-size: 18.5px;
}

.ProductPage_VendorDeltils_Link {
    text-decoration: none;
    color: rgb(250, 103, 103);
}

.ProductPage_VendorDeltils_Link:hover,
.ProductPage_VendorDeltils_Link:focus {
    text-decoration: none;
    color: rgb(250, 103, 103);
}

.ProductPage_Description {
    margin-top: 8%;
}

.ProductPage_Controls {
    margin-top: 1%;
}

.relatedProductsContainer {
    /* background-color: rgb(255, 247, 247); */
    max-height: 275px;
    border-top: 1px solid rgb(57, 57, 57);
    border-bottom: 1px solid rgb(57, 57, 57);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.ProductPage_RelatedProducts {
    margin-top: 8%;
}

.ProductPage_Reviews {
    margin-top: 8%;
}

.reviewsContainer {
    max-height: 275px;
    border-top: 2px solid rgb(255, 212, 212);
    border-bottom: 2px solid rgb(255, 212, 212);
    display: flex;
    overflow-x: auto;
    margin: 0% 0% 0% 0%;
}

.productReview {
    min-width: 55%;
    max-height: 255px;
    text-align: start;
    border-left: 1px solid rgb(255, 212, 212);
    border-right: 1px solid rgb(255, 212, 212);
    background-color: rgb(255, 255, 255);
    margin: 0% 0% 0% 0%;
}

.productReview_review {
    padding-left: 5%;
    padding-right: 2.5%;
    margin-top: 3%;
    margin-bottom: 4%;
}

.productReview_name {
    margin-top: 4%;
    margin-left: 5%;
}

.IpDropReviewsContainer {
    margin-top: 11%;
}

.reviewStarSpacer {
    width: 8px;
}

.fa-star-unchecked {
    color: rgb(190, 190, 190);
}

.addwrittenreviewtext {
    color: rgb(49, 49, 49);
    margin-top: 1%;
    margin-bottom: 1%;
}

.leavearating_rating_text {
    display: inline-block;
    padding-right: 3%;
}

.leavearating_rating {
    display: inline-block;
}

.writeareview_controls {
    color: rgb(250, 103, 103);
}

.writeareview_controls:first-child {
    margin-right: 10px;
}

.writeareview_controls_container {
    margin-top: 2.5%;
    margin-right: 1%;
}

.floatingTextarea_writeareview_container {
    margin-top: 3%;
}

.individualOrderPage {}

.individualOrderPage_details {
    border-radius: 5px;
    border: 1px solid rgb(250, 103, 103);
    margin: 7% 5% 4% 5%;
    padding: 2% 4% 4% 4%;
}

.individualOrderPage_details_sd {
    color: rgb(250, 103, 103);
    text-align: center;
    margin-top: 1%;
    margin-bottom: 2%;
}

.individualOrderPage_details_one {
    margin-bottom: 3%;
}

.individualOrderPage_details_two {
    margin-bottom: 3%;
}

.orderedIndividualProductsContainer {
    margin: 7% 5% 4% 5%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.orderedIndividualProductsContainer_Text {
    color: rgb(250, 103, 103);
    text-align: center;
    padding-bottom: 3%;
}

.thankyou_forOrdering {
    color: rgb(250, 103, 103);
    text-align: center;
    /* padding-bottom: 6%; */
}

.iop_user_bottom {
    margin-top: 5%;
}

.cancelOrder_IndividualOrderPage {
    margin-top: -10px;
    margin-bottom: 11%;

}

.cancelOrder_IndividualOrderPage button {
    /* color: white; */
    color: rgb(250, 103, 103);
    padding-top: 9px;
    padding-bottom: 9px;
    /* border: none; */
    border: 1px solid rgb(250, 103, 103);
    border-radius: 6px;
    /* background-color: rgb(250, 103, 103); */
    background-color: white;
    width: 100%;
}

.individualProductOrdered {}

.individualProductOrdered_ItemImage img {
    border-radius: 5px;
    height: 130px;
}

.individualProductOrdered_Info {
    margin: 4%;
}

.individualProductOrdered_ImgAndInfoContainer {
    padding: 1% 0px 4% 1%;
}



.forgotPasswordRoute {
    background-color: rgb(255, 255, 255);
}

.forgot-password_main {
    background-color: rgb(255, 255, 255);
}

.forgotPasswordRoute_container {
    background-color: rgb(255, 255, 255);
    padding-left: 5%;
    /* margin-top: 4%; */
    padding-bottom: 3.6%;
    padding-top: 4%;
}


.forgotPasswordRoute_Text {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.forgotPasswordRoute_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 39.5%;
}

.forgotPasswordRoute_InstructionsContainer {
    margin-top: 5.5%;
    margin-left: 1%;
    margin-right: 1%;
}

.forgotPasswordRoute_InstructionsContainer_text {
    margin-top: 0.5%;
}

.forgotPasswordRoute_InstructionsContainer_box {
    margin-top: 7%;
}

.forgotPasswordRoute_InstructionsContainer_button button {
    padding: 2.5% 12% 2.5% 12%;
    border-radius: 5px;
    border: none;
    background-color: rgb(250, 103, 103);
    color: white;
}

.forgotPasswordRoute_InstructionsContainer_button {
    margin-top: 8%;
}

.forgotPasswordRoute_text_verify {
    margin-left: 5%;
}

.edit-profile_cancel {
    margin-right: 4%;
}

.user_dp_and_name_editprofile {
    margin-bottom: 5%;
}

.edit-profile_edit_dp {
    padding-top: 1.4%;
    padding-bottom: 1%;
    color: rgb(250, 103, 103);
}


.changePasswordRoute_container {
    background-color: rgb(255, 255, 255);
    padding-left: 5%;
    /* margin-top: 4%; */
    padding-bottom: 3.6%;
    padding-top: 4%;
}

.changePasswordRoute_Text {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.changePasswordRoute_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 39.5%;
}

.changePassword_Password_container {
    margin-top: 6%;
}

.changePassword_Password_container_box {
    margin-top: 4%;
}

.cartItem {
    margin: 3%;
    padding: 3%;
    border-radius: 9px;
    /* border-bottom: 1px solid rgb(250, 103, 103); */
    border: 2px solid rgb(255, 216, 216);
    /* padding-bottom: 1%; */
}


.cartResultProduct {
    padding: 1% 0% 1% 0%;
    background-color: rgb(255, 255, 255);
}

.cartItemImgAndInfoContainer {}

.cartItemImage img {
    border-radius: 5px;
    height: 80px;
}

.cartItemInfo {
    /* margin: 4%; */
    margin: 2% 0% 2% 1%;
}

.cartItemInfo_ProductTitle {
    padding-top: 0.5%;
    display: block;
}

.cartItemInfo_ProductPrice {
    padding-bottom: 4%;
    display: block;
}

.cartItemControls_DecreaseQuantity {
    background-color: rgb(255, 255, 255);
    border: none;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
    width: 26px;
    color: black;
    /* width: 25px; */
}


.cartItemControls_IncreaseQuantity {
    background-color: rgb(255, 255, 255);
    border: none;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    width: 26px;
    color: black;
    /* width: 25px; */
}

.cartItemControls_Quantity {
    background-color: rgb(255, 231, 231);
    border: none;
    text-align: center;
    width: 40px;
    padding: 5px 12px 5px 12px;
}

.cartItemControls_button {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(250, 103, 103);
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: black;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 13px;
}

.cartItemControls {
    margin-left: 3px;
    margin-top: 2.8%;
}

.cartItemControls_qtt {
    margin-left: 11px;
    margin-right: 5px;
    border-radius: 5px;
}

.cartRoute {
    /* overflow-y: auto; */
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.CartCheckoutButton {
    width: 100%;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: rgb(250, 103, 103);
}

.myCart {
    padding-top: 2px;
    padding-left: 3%;
    margin-left: 2%;
    color: rgb(90, 90, 90);
}

.smallNavBar_container {
    padding-left: 5%;
    padding-top: 4%;
    padding-bottom: 3.6%;
    background-color: rgb(255, 255, 255);
}

.myCartContainer img {
    margin-bottom: 0.8%;
}

.myCartControls {
    /* border-top: 1px solid rgb(90, 90, 90); */
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding-top: 4%;
    margin-bottom: 8%;

}

.MyCartItemsContainer {
    /* border-top: 1px solid rgb(250, 103, 103); */
}

.myCartControls_subtotal {
    margin-top: 2%;
}

.myCartControls_subtotal span {
    /* margin-left: 3.5px; */
    display: block;
}

.myCartControls_CartCheckoutButton {
    margin-top: 3%;
}

.cartIsEmpty {
    margin: 5% 10% 5% 10%;
    padding: 5% 10% 5% 10%;
    text-align: center;
}

.ProductPage_NavBar_img_logo_Container {
    display: inline-block;
    margin-left: 58%;
}

.ProductPage_NavBar_BackAndTitle {
    display: inline-block;
}

.myCartControls_CartCheckoutButton_disclaimer {
    margin-left: 3px;
}

.myCartControls_CartCheckoutButton_disclaimer_cont {
    margin-top: 3%;
}

.cartItemControls_image {
    background-color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    padding: 5px 12px 5px 12px;
    color: black;
    margin-left: 3%;
    font-size: 13px;
}

.changePassword_button button {
    padding: 2.5% 12% 2.5% 12%;
    border-radius: 5px;
    border: none;
    background-color: rgb(250, 103, 103);
    color: white;
}

.changePassword_button {
    margin-top: 8%;
}

.optionSet {
    color: rgb(0, 0, 0);
}

.optionReset {
    color: rgb(0, 0, 0);
    color: rgb(250, 103, 103);
}

.headingSignin {
    margin-top: 6%;
}

.options label {
    background-color: rgb(255, 255, 255);
    border: none;

}

.link {
    text-decoration: none;
}

.signInButton {
    margin-top: 9%;
}

.signInButton button {
    width: 100%;
    height: 42px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    padding: 3px 30px;
    color: rgb(255, 255, 255);
    background-color: rgb(250, 103, 103);
}

.forgotPasswordRedirect {
    padding-top: 3px;
}

.signInOtherControls_Control {
    font-size: 14px;
    background-color: rgb(255, 238, 238);
    padding: 2.6% 6% 2.6% 6%;
    color: rgb(0, 0, 0);
    font-weight: 450;
    border-radius: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.signInOtherControls_Container {
    margin-top: 7%;
}

.searchResultProduct {
    padding: 3% 0% 3% 0%;
    border-top: 2px solid rgb(255, 212, 212);
    background-color: rgb(255, 255, 255);
}


.searchResultProduct:last-child {
    border-bottom: 2px solid rgb(255, 212, 212);
}

/* .searchResultProduct:nth-child(1) {
    border-top: 2px solid rgb(250, 103, 103);
} */
.productImage {
    height: 145px;
    width: 145px;
    border-radius: 6px;
    border: 2px solid rgb(240, 240, 240);
    padding: 5%;
}

/* background-color: rgb(249, 216, 216); */
.productTitle {
    color: black;
    display: block;
}

.productPrice {
    color: black;
    display: block;
}

.productBrand {
    display: block;
    color: rgb(82, 82, 82);
    font-size: 14px;
}

.productRating {
    display: block;
}

.productDetails {

    /* padding-left: 0px; */
}

.checked {
    color: rgb(250, 103, 103);
}

.noOfRatings {
    font-size: 14px;
    color: rgb(82, 82, 82);

}

.searchResultProduct_discount {
    color: rgb(82, 82, 82);
}

.loadedDataSeparaterForItems {
    margin-top: 2%;
    border-bottom: 2px solid rgb(255, 216, 216);
    margin-bottom: 4.8%;
}

.seller_addProduct_button {
    font-weight: 500;
}

.form-switch .form-check-input:focus {
    background-image: url("../Icons/icons8-filled-circle-100(1).png");
}

.form-check-input:focus {
    border-color: rgba(250, 103, 103, 0.8);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 25%);
}

.form-check-input:checked {
    background-color: rgb(250 103 103);
    border-color: rgb(250 103 103);
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("../Icons/icons8-filled-circle-100(2).png");
}

.form-control:focus {
    border-color: rgba(250, 103, 103, 0.180);
    box-shadow: 0 0 0 0.215rem rgb(250 103 103 / 18%);
}

.form-select:focus {
    border-color: rgba(250, 103, 103, 0.180);
    box-shadow: 0 0 0 0.215rem rgb(250 103 103 / 18%);
}

.seller_updateProduct_cancelbutton:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}

.seller_addProduct_button:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}


.AddressForOrder_AddressItem {
    border-radius: 7px;
    border: 2px solid rgb(237, 237, 237);
    margin-top: 4.5%;
}