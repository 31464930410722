.seller-root_heading1 {
    text-align: center;
}

.seller-root {
    background-color: white;
}

.seller-root_banner {
    height: 240px;
}

.seller-root_heading1_text {
    padding-top: 4%;
    background-color: rgb(255, 255, 255);
    /* color: rgb(250, 103, 103); */
}

.seller-root_startSelling button {
    border-radius: 5px;
    border: 1px solid rgb(250, 103, 103);
    background-color: rgb(250, 103, 103);
    color: white;
    padding: 1.5% 10% 1.5% 10%;
}

.seller-root_tac {
    font-size: 13px;
    padding-top: 2%;
    color: grey;
    margin-bottom: 9%;
}

.whysellonswoop {
    margin-top: 5%;
}

.whysellonswoop_reasonsContainer {
    padding: 6% 7% 6% 7%;
    margin-top: 4%;
    border-radius: 10px;
    /* border: 1px solid rgb(138, 138, 138); */
    border: 1px solid rgb(250, 103, 103);
}

.whysellonswoop_img {}

.whysellonswoop_text {
    color: rgb(84, 84, 84);
}

.seller-root_becomeaseller {
    line-height: 33px;
    font-size: 30px;
    color: rgb(250, 103, 103);
}

.seller_root_image {
    padding-top: 20px;
    padding-bottom: 20px;
    /* margin-top: 25px; */
    background-color: rgb(224, 207, 255);
}