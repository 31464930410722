.analyticsContainerSeller {
    margin-top: 6%;
    background-color: white;
}

.analysisitem_img {
    vertical-align: middle;
    height: 25px;
}

.analysisitem:nth-child(1) {
    padding: 20px 25px 18px 25px;
    border-top: 3px solid rgb(255, 232, 232);
    border-left: 3px solid rgb(255, 232, 232);
    border-right: 1.5px solid rgb(255, 232, 232);
    border-bottom: 1.5px solid rgb(255, 232, 232);
    border-radius: 7px;
    background-color: white;
}

.analysisitem:nth-child(2) {
    padding: 20px 25px 18px 25px;
    border-top: 3px solid rgb(255, 232, 232);
    border-left: 1.5px solid rgb(255, 232, 232);
    border-right: 3px solid rgb(255, 232, 232);
    border-bottom: 1.5px solid rgb(255, 232, 232);
    border-radius: 7px;
    background-color: white;
}

.analysisitem:nth-child(3) {
    padding: 20px 25px 18px 25px;
    border-top: 1.5px solid rgb(255, 232, 232);
    border-left: 3px solid rgb(255, 232, 232);
    border-right: 1.5px solid rgb(255, 232, 232);
    border-bottom: 3px solid rgb(255, 232, 232);
    border-radius: 7px;
    background-color: white;
}

.analysisitem:nth-child(4) {
    padding: 20px 25px 18px 25px;
    border-top: 1.5px solid rgb(255, 232, 232);
    border-left: 1.5px solid rgb(255, 232, 232);
    border-right: 3px solid rgb(255, 232, 232);
    border-bottom: 3px solid rgb(255, 232, 232);
    border-radius: 7px;
    background-color: white;
}

.analysisitem_text {
    margin-top: 4%;
    color: rgb(250, 103, 103);
}

.analysisitem_textdp {
    padding-left: 8px;
}

.analysisitem_textdp {
    line-height: initial;
    font-size: 14px;
}

.title_ProductAnalytics {
    margin-top: 35px;
    margin-bottom: 16px;
}