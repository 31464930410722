.sellerQRCodeDrawer {
    height: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    right: 0;
    height: 80%;
    width: 100%;
    z-index: 200;
    transition: transform 0.3s ease;
    transform: translateY(300%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.sellerQRCodeDrawer.open {
    transform: translateY(0%);
}

.SellerQRCodeDrawer_Details_name {
    text-align: center;
    color: rgb(0, 0, 0);
    /* color: rgb(250, 103, 103); */
    padding-bottom: 3%;
    border-bottom: 1.5px solid rgb(0, 0, 0);
    /* border-bottom: 2px solid rgb(250, 103, 103); */
}

.SellerQRCodeDrawer_Details {
    margin-top: 5%;
    padding: 4% 5% 5% 5%;
}

.qrCodeSellerContainer {
    margin-top: 6%;
}

.SellerQRCodeDrawer_Details_id {
    font-size: 14px;
    text-align: center;
    color: rgb(250, 103, 103);
}

.SellerQRCodeDrawer_Details_id_text {
    font-size: 18px;
}

.SellerQRCodeDrawer_controls {
    margin-bottom: 6%;
}

.SellerQRCodeDrawer_controls button {
    font-weight: bold;
    font-size: 15px;
    margin: 2%;
    border: 1px solid rgb(250, 103, 103);
    background-color: white;
    border-radius: 10px;
    padding: 7px 15px 7px 15px;
}