.wantAPhysicalQR_main {
    margin-top: 5.5%;
    margin-bottom: 10%;
}

.wantAPhysicalQR_heading {
    font-size: 30px;
    color: rgb(250, 103, 103);
}

.WPQR_buttons_c {
    background-color: rgb(250, 103, 103);
    border-radius: 5px;
    border: none;
    padding: 2.5%;
    width: 100%;
    color: white;
    font-size: 17px;
}

.WPQR_buttons_c:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}

.WPQR_buttons {
    margin-top: 6.5%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(250, 103, 103);
    padding: 2.5%;
    width: 100%;
    color: rgb(250, 103, 103);
    font-size: 16px;
}

.WPQR_buttons:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(250 103 103 / 38%);
}


.WPQR_buttons_black {
    margin-top: 6.5%;
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(0, 0, 0);
    padding: 2.5%;
    width: 100%;
    color: rgb(0, 0, 0);
    font-size: 16px;
}

.WPQR_buttons_black:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 38%);
}


.WPQR_buttons_controls {
    margin-top: 10%;
}

.whyPhysicalQR_Container {
    margin-top: 9%;
}


.WPQR_tagline {
    font-size: 16px;
    padding-top: 3%;
}

.WPQR_PhysicalQR {
    margin-top: 9%;
}

.WPQR_tagline_Free {
    margin-top: -3px;
    font-size: 37px;
    color: rgb(250, 103, 103);
}

.WPQR_tagline_moretext {
    margin-top: -5px;
    font-size: 23px;
    color: rgb(250, 103, 103);
}

.enterShopNameContainer {
    border: 2px solid rgb(230, 230, 230);
    background-color: white;
    border-radius: 4px;
    padding: 5% 5% 8.5% 5%;
}

.enterShopNameContainer_Controls b {
    font-size: 15px;
    color: rgb(250, 103, 103);
}

.enterShopNameContainer_Controls b:first-child {
    font-size: 15px;
    margin: 0px 4% 0px 0px;
    color: rgb(250, 103, 103);
}

.enterShopNameContainer_Controls {
    margin: 1% 0px 0% 0px;
}

.whyPhysicalQR_Heading {
    font-size: 20px;
    color: rgb(250, 103, 103);
}